import { useCallback, useContext, useEffect, useState } from 'react';
import { useNetwork, useProvider } from 'wagmi';

import { GlobalContext, UserContext } from '../../context';
import {
  formatValue,
  formatWei,
  fromWei,
  getAddressToken,
  getPoolData,
  getTokenDecimals,
  loadContract,
  toBigNumber,
  toWei,
} from '../../context/utils';
import { ChainIdType, RefetchState } from '../../interfaces';

async function getUserVaultData(
  vault,
  library,
  wallet: string,
  priceData,
  tokenList,
  chainId: ChainIdType,
) {
  try {
    const { vaultAddress, protocol, earn, version } = vault;

    let vaultContract;
    let dividend;
    let lpBalanceWei;
    let underlying;

    if (version.isCompounding) {
      vaultContract = loadContract(
        'VaultVRC20',
        chainId,
        library,
        vaultAddress,
      );
      dividend = 1;
      const shares = await vaultContract.balanceOf(wallet);
      lpBalanceWei = await vaultContract.convertToAssets(shares);
      underlying = await vaultContract.asset();
    } else {
      vaultContract = loadContract('VaultV2', chainId, library, vaultAddress);
      dividend = await vaultContract.dividendOf(wallet);
      lpBalanceWei = await vaultContract.balanceOf(wallet);
      underlying = await vaultContract.underlying();
    }

    let balance = 0;
    let balanceBN = toBigNumber(0);
    let balanceWei = toBigNumber(0);

    if (protocol === 'Curve') {
      const curvePoolContract = loadContract('CurvePoolRead', chainId, library);

      if (Number(fromWei(lpBalanceWei)) > 0) {
        balanceWei = await curvePoolContract.calc_withdraw_one_coin(
          lpBalanceWei,
          0,
        );

        balance = formatWei(
          getAddressToken('DAI', tokenList),
          balanceWei,
          tokenList,
        );
        balanceBN = balanceWei;
      }
    } else {
      if (
        underlying.toLowerCase() ===
          '0xe89fae1b4ada2c869f05a0c96c87022dadc7709a' ||
        underlying.toLowerCase() ===
          '0x74214f5d8aa71b8dc921d8a963a1ba3605050781'
      ) {
        const r = await getPoolData(
          underlying,
          lpBalanceWei,
          library,
          tokenList,
          chainId,
        );

        balanceWei = toWei(String(r.usdValue));
      } else {
        if (version.isLp) {
          // This means that is a LP token
          const response = await getPoolData(
            underlying,
            lpBalanceWei,
            library,
            tokenList,
            chainId,
          );

          balanceWei = toWei(String(response.usdValue.toFixed(18)));
        } else {
          if (version.isCompounding) {
            balanceWei = toWei(
              (Number(lpBalanceWei / 1e18) * vault.depositTokenPrice).toFixed(
                18,
              ),
            );
          } else {
            balanceWei = toWei(
              (Number(lpBalanceWei / 1e18) * priceData[earn]).toFixed(18),
            );
          }
        }
      }

      balance = Number(fromWei(String(balanceWei)));
      balanceBN = toBigNumber(String(balanceWei));
    }

    return {
      ...vault,
      earned: formatValue(dividend, getTokenDecimals(earn, tokenList)),
      balance,
      balanceWei,
      balanceBN,
      lpBalance: fromWei(lpBalanceWei),
      lpBalanceWei,
      lpBalanceBN: toBigNumber(lpBalanceWei),
      lastHarvest: 1621383453,
      liquidity: String(vault.vaultDeposits),
    };
  } catch (error) {
    console.error(error);
  }
}

export function useUserVaultData(): [RefetchState, any] {
  const { chain } = useNetwork();

  const {
    state: { wallet },
    dispatch,
  } = useContext(UserContext);
  const {
    state: { vaultData, priceData, tokenList },
  } = useContext(GlobalContext);
  const provider = useProvider();

  const [refetch, setRefetch] = useState<boolean>(false);
  const [userVaultData, setUserVaultData] = useState<any>(null);
  const [loadingUserVaultData, setLoadingUserVaultData] =
    useState<RefetchState>({
      isLoading: true,
      error: null,
      refetch: null,
    });

  const fetchUserVaultData = useCallback(async () => {
    const uVaultData = [];
    const vaultPromises = [];

    if (!chain) return uVaultData;

    for (let i = 0; i < vaultData.length; i++) {
      vaultPromises.push(
        getUserVaultData(
          vaultData[i],
          provider,
          wallet,
          priceData,
          tokenList,
          chain.id,
        ),
      );
    }

    const results = await Promise.all(vaultPromises);

    for (const vault of results) {
      uVaultData.push(vault);
    }

    return uVaultData;
  }, [chain, refetch, vaultData]);

  useEffect(() => {
    setLoadingUserVaultData((pastLoadingUserVaultData) => ({
      ...pastLoadingUserVaultData,
      isLoading: true,
    }));

    fetchUserVaultData()
      .then((uVaultData) => {
        setUserVaultData(uVaultData);
        dispatch({
          type: 'SET_VAULT_DATA',
          payload: uVaultData,
        });
        setLoadingUserVaultData((pastLoadingUserVaultData) => ({
          ...pastLoadingUserVaultData,
          refetch: () => setRefetch(true),
          isLoading: false,
        }));
        if (refetch) setRefetch(false);
      })
      .catch(() => {
        const errorToShow = new Error('Error fetching user vault data');
        setLoadingUserVaultData((pastLoadingUserVaultData) => ({
          ...pastLoadingUserVaultData,
          refetch: () => setRefetch(true),
          error: errorToShow,
        }));
        if (refetch) setRefetch(false);
      });
  }, [chain, refetch, fetchUserVaultData]);

  return [loadingUserVaultData, userVaultData];
}
