import { GraphQLClient } from 'graphql-request';

// https://thegraph.com/legacy-explorer/subgraph/ethalend/etha-v1
export const ethaProtocolClientPolygon = new GraphQLClient(
  'https://api.thegraph.com/subgraphs/name/ethalend/etha-v1',
);

export const ethaProtocolClientAvax = new GraphQLClient(
  'https://api.thegraph.com/subgraphs/name/wafflemakr/etha-subgraph-avax',
);

// https://thegraph.com/legacy-explorer/subgraph/sameepsi/quickswap06
export const quickClient = new GraphQLClient(
  'https://polygon.furadao.org/subgraphs/name/quickswap',
);

// https://thegraph.com/legacy-explorer/subgraph/creamfinancedev/cream-polygon
export const creamClient = new GraphQLClient(
  'https://api.thegraph.com/subgraphs/name/creamfinancedev/cream-polygon',
);

// https://thegraph.com/legacy-explorer/subgraph/aave/aave-v2-matic
export const aaveClient = new GraphQLClient(
  'https://api.thegraph.com/subgraphs/name/aave/aave-v2-matic',
);

export const subgraphClientByNetwork: { [key: number]: GraphQLClient } = {
  137: ethaProtocolClientPolygon,
  43114: ethaProtocolClientAvax,
};
